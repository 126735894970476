import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Seo from "../components/Seo";
import Services from "../components/Services";
import BookingBlock from "../components/BookingBlock";

export default function SingleService({ data }) {
  const service = data.contentfulPalvelut;
  return (
    <Layout
      theme="dark"
      heroImage={service?.serviceImage?.fluid?.src}
      hero={false}
    >
      <Seo title={`${service?.serviceName} - Kauneuden ilo`} />
      <section className={service?.slug}>
        <Link to="/palvelut" className="back-button">
          &lsaquo; Palvelut
        </Link>
        <h1>
          {service?.serviceName} {service?.textIcon}
        </h1>
        {service.serviceDescription &&
          renderRichText(service.serviceDescription)}
        {service?.serviceAndPrice && (
          <section className="price-table">
            <h2>Hinnasto</h2>
            <ul className="services-and-prices">
              {service.serviceAndPrice &&
                service.serviceAndPrice.length > 0 &&
                service.serviceAndPrice.map((service) => (
                  <li key={service.id}>
                    <div className="name-and-description">
                      <span className="name">{service?.serviceName}</span>
                      <span className="description">
                        {service.description && service?.description}
                      </span>
                    </div>
                    <span className="price">{service?.price} &euro;</span>
                  </li>
                ))}
            </ul>
          </section>
        )}
      </section>
      <BookingBlock />
      <Services />
    </Layout>
  );
}
export const query = graphql`
  query ($slug: String!) {
    contentfulPalvelut(slug: { eq: $slug }) {
      textIcon
      slug
      serviceName
      serviceDescription {
        raw
      }
      serviceImage {
        fluid {
          src
          srcSetWebp
        }
      }
      serviceAndPrice {
        serviceName
        price
        description
        id
      }
    }
  }
`;
